import {
  SET_USER_DATA,
  SET_GOOGLE_USER_DATA,
  SET_FACEBOOK_USER_DATA,
  LOGOUT_USER,
  SET_LOGGED_IN,
  SET_COOKIES_ACCEPTED,
  GET_USER_FAVOURITES,
  REMOVE_USER_FAVOURITES,
} from "../actions";

const accountData = (
  state = {
    loggedIn: false,
    cookiesAccepted: false,
    favouriteRestaurants: [],
  },
  action
) => {
  switch (action.type) {
    case SET_LOGGED_IN:
      return { ...state, loggedIn: action.val };

    case SET_USER_DATA:
      return { ...state, userData: action.userData };

    case LOGOUT_USER:
      let { userData, ...removedUserData } = state;
      // return { ...removedUserData, loggedIn: false };
      return { ...state, userData: {}, loggedIn: false };

    case SET_GOOGLE_USER_DATA:
      return { ...state, googleUserData: action.userData };

    case SET_FACEBOOK_USER_DATA:
      return { ...state, facebookUserData: action.userData };

    case SET_COOKIES_ACCEPTED:
      return { ...state, cookiesAccepted: action.val };

    case GET_USER_FAVOURITES:
      return { ...state, favouriteRestaurants: action.favourites.restaurants };

    case REMOVE_USER_FAVOURITES:
      return { ...state, favouriteRestaurants: action.favourites };

    default:
      return state;
  }
};
export default accountData;
